<template>
  <div>
    <button
      class="btn btn-icon btn-light-success mr-1"
      type="button"
      v-b-tooltip.noninteractive.hover.left
      title="Configurações"
      @click="abrir()"
    >
      <i class="fa-solid fa-cog"></i>
    </button>

    <b-modal
      ref="mdlConfiguracoesWhatsappOficial"
      title="Perfil do Whatsapp Oficial"
      title-class="text-white"
      header-close-variant="white"
      header-class="whatsapp-info-header"
      @hidden="limpar"
      @ok="salvar"
      @shown="validacao"
      ok-variant="ok-whatsapp"
      ok-title="Atualizar"
      ok-only
      cancel-title="Cancelar"
      body-bg-variant="whatsapp-info"
      footer-bg-variant="whatsapp-info"
    >
      <b-form ref="frmWhatsapp">
        <b-form-group>
          <div
            style="
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <div style="position: relative">
              <b-img
                :src="frmWhatsapp.profile.profile_picture_url"
                alt="Foto de perfil"
                width="150"
                height="150"
                rounded="circle"
              ></b-img>
              <button
                class="btn btn-icon btn-light-primary"
                type="button"
                @click="uploadImage"
                v-b-tooltip.noninteractive.hover.left
                title="Editar foto de perfil"
                style="
                  position: absolute;
                  bottom: 0;
                  right: 0;
                  border-radius: 100%;
                  background-color: #ff7f00;
                "
              >
                <i class="fa-solid fa-camera" style="color: white"></i>
              </button>
            </div>
          </div>
          <input
            ref="inputFile"
            type="file"
            accept="image/jpg, image/jpeg, image/png"
            style="visibility: hidden; width: 0px"
          />
        </b-form-group>
        <b-form-group>
          <label>Número de telefone</label>
          <b-form-input
            name="phone_number"
            :value="conta.phone"
            readonly
            style="cursor: not-allowed"
            :disabled="true"
          ></b-form-input>
          <label class="input-profile">Sobre</label>
          <b-form-textarea
            id="description"
            v-model="frmWhatsapp.profile.description"
            placeholder="Escreva uma descrição da sua empresa"
            rows="6"
          ></b-form-textarea>
          <label class="input-profile">Endereço</label>
          <b-form-input
            name="address"
            v-model="frmWhatsapp.profile.address"
          ></b-form-input>
          <label class="input-profile">Sites</label>
          <div class="d-flex">
            <b-form-input
              name="website1"
              v-model="frmWhatsapp.profile.websites[0]"
              placeholder="Digite o site"
              style="margin-right: 10px"
            ></b-form-input>
            <b-form-input
              name="website2"
              v-model="frmWhatsapp.profile.websites[1]"
              placeholder="Digite o site"
            ></b-form-input>
          </div>
          <label class="input-profile">Recado:</label>
          <b-form-textarea
            name="about"
            v-model="frmWhatsapp.profile.about"
            placeholder="Escreva uma recado da sua empresa"
            rows="3"
          ></b-form-textarea>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";

import { mapGetters } from "vuex";

export default {
  props: {
    conta: Object
  },

  data() {
    return {
      frmWhatsapp: {
        idConta: null,
        profile: {
          about: "",
          messaging_product: "whatsapp",
          profile_picture_url: "",
          address: "",
          description: "",
          websites: ["", ""]
        }
      }
    };
  },

  computed: {
    ...mapGetters(["validatorErrors"])
  },

  methods: {
    limpar() {
      this.frmWhatsapp.idConta = null;
      this.frmWhatsapp.profile.about = "";
      this.frmWhatsapp.profile.messaging_product = "whatsapp";
      this.frmWhatsapp.profile.profile_picture_url = "";
      this.frmWhatsapp.profile.address = "";
      this.frmWhatsapp.profile.description = "";
      this.frmWhatsapp.profile.websites = ["", ""];
    },

    abrir() {
      this.limpar();

      this.frmWhatsapp.idConta = this.conta.id;

      ApiService.post("conta/obter-whatsapp-profile", {
        conta_id: this.conta.id
      }).then((response) => {
        const profile = response.data.data;

        this.frmWhatsapp.profile.about = profile?.about;

        this.frmWhatsapp.profile.profile_picture_url =
          profile.profile_picture_url;

        this.frmWhatsapp.profile.address = profile.address;
        this.frmWhatsapp.profile.description = profile.description;
        this.frmWhatsapp.profile.websites[0] =
          profile.websites && profile.websites[0] ? profile.websites[0] : "";
        this.frmWhatsapp.profile.websites[1] =
          profile.websites && profile.websites[1] ? profile.websites[1] : "";

        this.$refs["mdlConfiguracoesWhatsappOficial"].show();
      });
    },

    validacao() {
      const frmWhatsapp = this.$refs["frmWhatsapp"];

      this.fv = formValidation(frmWhatsapp, {
        fields: {
          recado: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap()
        }
      });
    },

    uploadImage() {
      this.$refs.inputFile.click();

      this.$refs.inputFile.addEventListener("change", this.uploadImageProfile);
    },

    uploadImageProfile(event) {
      const file = event.target.files[0];

      let formData = new FormData();

      formData.append("file", file);
      formData.append("conta_id", this.conta.id);

      ApiService.post("conta/upload-file-whatsapp", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then((response) => {
          this.frmWhatsapp.profile.profile_picture_handle = response.data.data;
        })
        .catch(() => {
          Swal.fire({
            title: "MENSAGEM",
            text: "Erro ao fazer upload da imagem!",
            icon: "error",
            heightAuto: false
          });
        });

      this.frmWhatsapp.profile.profile_picture_url = URL.createObjectURL(file);
    },

    salvar() {
      this.fv.validate().then((status) => {
        if (status == "Valid") {
          ApiService.post("conta/atualizar-whatsapp-profile", {
            ...this.frmWhatsapp,
            conta_id: this.conta.id
          })
            .then(() => {
              this.$emit("aba-alterada", false);

              Swal.fire({
                title: "MENSAGEM",
                text: "Atualizado com sucesso!",
                icon: "success",
                heightAuto: false
              });
            })
            .catch(() => {
              for (const error in this.validatorErrors) {
                this.fv
                  .updateValidatorOption(
                    error,
                    "blank",
                    "message",
                    this.validatorErrors[error]
                  )
                  .updateFieldStatus(error, "Invalid", "blank");
              }
            });
        }
      });
    }
  }
};
</script>

<style>
@font-face {
  font-family: "Gotham Rounded";
  src: url("/fonts/GothamRounded-Medium.otf") format("opentype"),
    url("/fonts/GothamRounded-MediumItalic.otf") format("opentype"),
    url("/fonts/GothamRounded-Light.otf") format("opentype"),
    url("/fonts/GothamRounded-LightItalic.otf") format("opentype"),
    url("/fonts/GothamRounded-Bold.otf") format("opentype"),
    url("/fonts/GothamRounded-BoldItalic.otf") format("opentype"),
    url("/fonts/GothamRounded-Book.otf") format("opentype"),
    url("/fonts/GothamRounded-BookItalic.otf") format("opentype");
}

.ft-whatsapp-info,
.bg-whatsapp-info {
  background-color: #f0f0f0;
}

.whatsapp-info-header {
  color: #fff;
  background-color: #ff7f00;
  font-family: "Gotham Rounded";
}

.input-profile {
  margin-top: 10px;
}

.btn-ok-whatsapp {
  background-color: #462181;
  color: #fff;
}

.btn-ok-whatsapp:hover {
  color: #ececec;
}
</style>
